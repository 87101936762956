import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import type { LoaderData } from '@ubo/losse-sjedel'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import { useLoaderData } from '@ubo/losse-sjedel'
import S from '~/components/elements/Icons/S'
import type { FooterLoaderData } from '~/components/layout/Footer'
import Phone from '~/components/elements/Icons/Phone'
import Mail from '~/components/elements/Icons/Mail'
import WhatsApp from '~/components/elements/Icons/WhatsApp'

export default function FormContact({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const { footer, page } = useLoaderData<FooterLoaderData & LoaderData>()
  return (
    <section className="sm:mb-20 mb-10" data-component="FromContact">
      <div className="bg-dark pt-44 text-white">
        <div className="container relative lg:grid flex flex-col grid-cols-2 2xl:gap-x-36 xl:gap-x-20 gap-x-10">
          <div>
            <Breadcrumbs className="2xl:mb-48 mb-36" title={page.title as string} />
            <Content className="pb-32">{fields.title}</Content>

            <S className="absolute left-10 pt-10 top-0 lg:h-full lg:w-auto w-[300px] h-auto" />
          </div>

          <div className="bg-gray 2xl:px-24 xl:px-16 sm:px-10 px-8 relative lg:top-24 sm:py-10 py-8 lg:-mb-44 mb-10">
            <Content className="children-h2:text-dark xl:children-h2:text-4xl sm:children-h2:text-3xl children-h2:text-2xl mb-6">
              {fields.description}
            </Content>
            <Form
              extraTypes={{}}
              data={fields.form}
              renderLoading={() => <Loading />}
              renderStatus={({ status, content }) => <Content>{content}</Content>}
            />
          </div>
        </div>
      </div>

      <div className="bg-[#D9D9D9] py-10">
        <div className="container xl:max-w-screen-xl mx-auto grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1">
          <div>
            <Content className="children-strong:mb-2 children-strong:block mb-6">{footer.right?.description}</Content>

            <div className="flex flex-col gap-y-2 mb-2">
              <a className="flex items-center" href={footer.right?.phone?.url || ''}>
                <Phone className="mr-3 w-5 h-5" />
                {footer.right?.phone?.title}
              </a>
              <a className="flex items-center" href={footer.right?.email?.url || ''}>
                <Mail className="mr-3 w-5 h-5" />
                {footer.right?.email?.title}
              </a>
              <a className="flex items-center" href={footer.right?.whatsapp?.url || ''}>
                <WhatsApp className="mr-3 w-5 h-5" />
                {footer.right?.whatsapp?.title}
              </a>
            </div>

            <div className="flex items-center gap-x-4 mb-6">
              {footer.subfooter?.socialMedia?.map((socialMedia) => (
                <a key={socialMedia?.url} className="invert" href={socialMedia?.url || ''}>
                  <LossePlaatjie className="hover:scale-[1.05] grayscale transition-transform" src={socialMedia?.icon} />
                </a>
              ))}
            </div>

            <Content>{footer.right?.omschrijvingNa}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
