import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import Phone from '~/components/elements/Icons/Phone'
import WhatsApp from '~/components/elements/Icons/WhatsApp'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextAdvisor({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section className="sm:mb-32 mb-10 mt-10" data-component="TextAdvisor" id="text-adviseur">
      <div className="container xl:max-w-screen-xl mx-auto lg:grid flex flex-col xl:grid-cols-3 lg:grid-cols-2 xl:gap-x-16 gap-x-8 gap-y-8">
        <div className="xl:col-span-2">
          <Content className="children-h2:text-dark/30 mb-8">{fields.title}</Content>

          <div className={clsx(fields?.paddingBigScreen && 'xl:pl-28', 'pl-0 xl:pr-10')}>
            <Content>{fields.description}</Content>
          </div>
        </div>

        <div>
          <div className="bg-gray text-white sm:px-8 px-6 sm:py-8 py-6 pr-24 relative sm:mt-24 mt-2 max-w-[370px] mr-auto">
            <h3 className="font-bold text-xl text-dark mb-3">{fields.advisor?.fields?.title}</h3>
            <Content className="mb-8">{fields.advisor?.fields?.description}</Content>

            <LosseLink className="flex items-center mb-4" to={fields.advisor?.fields?.phone?.url}>
              <Phone className="mr-2 w-6 h-6" />
              <span>{fields.advisor?.fields?.phone?.title}</span>
            </LosseLink>

            <LosseLink className="flex items-center" to={fields.advisor?.fields?.whatsapp?.url}>
              <WhatsApp className="mr-2 w-6 h-6" />
              <span>{fields.advisor?.fields?.whatsapp?.title}</span>
            </LosseLink>

            <LossePlaatjie
              className="absolute sm:-right-20 -right-12 bottom-0 sm:w-[200px] w-[150px] pl-4"
              src={fields.advisor?.fields?.imagePerson}
              maxWidth={300}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
