import { LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import { LosseLink, type LoaderData } from '@ubo/losse-sjedel'
import type { Component_Footer } from '~/graphql/types'
import Content from '../elements/Content'
import Mail from '../elements/Icons/Mail'
import Phone from '../elements/Icons/Phone'
import S from '../elements/Icons/S'
import WhatsApp from '../elements/Icons/WhatsApp'

export interface FooterLoaderData extends Omit<LoaderData, 'footer'> {
  footer: Component_Footer
}

export default function Footer() {
  const { footer } = useLoaderData<FooterLoaderData>()

  return (
    <footer className="bg-dark text-white xl:pt-28 sm:pt-14 pt-10 pb-10 relative">
      <div className="container max-w-screen-xl mx-auto relative z-10 xl:px-0">
        <div className="sm:grid flex flex-col grid-cols-2 gap-y-10">
          <div>
            <Content className="sm:mb-14 mb-6">{footer.left?.title}</Content>

            <div className="flex flex-col items-start">
              <LosseLink src={footer.left?.callToActionFirst} className="button w-[205px] mb-3" />
              <LosseLink src={footer.left?.callToActionSecond} className="button-transparent w-[205px]" />
            </div>
          </div>

          <div className="md:grid flex flex-col grid-cols-2 gap-y-6">
            <div className="flex flex-col gap-y-3">
              {footer.menuFooter?.map((item) => (
                <LosseLink key={item?.link?.title} src={item?.link} className="text-white text-lg hover:underline" />
              ))}
            </div>

            <div>
              <Content className="children-strong:mb-2 children-strong:block mb-6">{footer.right?.description}</Content>

              <div className="flex flex-col gap-y-2 mb-6">
                <a className="flex items-center hover:underline" href={footer.right?.phone?.url || ''}>
                  <Phone className="mr-3 w-5 h-5" />
                  {footer.right?.phone?.title}
                </a>
                <a className="flex items-center hover:underline" href={footer.right?.email?.url || ''}>
                  <Mail className="mr-3 w-5 h-5" />
                  {footer.right?.email?.title}
                </a>
                <a className="flex items-center hover:underline" href={footer.right?.whatsapp?.url || ''}>
                  <WhatsApp className="mr-3 w-5 h-5" />
                  {footer.right?.whatsapp?.title}
                </a>
              </div>

              <Content>{footer.right?.omschrijvingNa}</Content>
            </div>
          </div>
        </div>

        <div className="mt-20 flex sm:flex-row flex-col gap-y-6 justify-between">
          <div className="flex gap-x-10">
            {footer.subfooter?.links?.map((link) => (
              <LosseLink key={link?.link?.title} src={link?.link} className="text-white/70 hover:underline" />
            ))}
          </div>

          <div className="flex items-center gap-x-4">
            <Content className="mr-4">{footer.subfooter?.labelSocialMedia}</Content>

            {footer.subfooter?.socialMedia?.map((socialMedia) => (
              <a key={socialMedia?.url} href={socialMedia?.url || ''} target="_blank">
                <LossePlaatjie className="hover:scale-[1.05] transition-transform" src={socialMedia?.icon} />
              </a>
            ))}
          </div>
        </div>
      </div>
      <S className="absolute left-0 bottom-0 w-[540px] h-auto" />
    </footer>
  )
}
